

<div *ngIf="formStatus != 1">
    <input type="text" class="fe-input" placeholder="Name" [(ngModel)]="formData.name" ngModel />
    <input type="mail" class="fe-input" placeholder="E-Mail" [(ngModel)]="formData.mail" ngModel />
    <textarea type="mail" class="fe-input" placeholder="Ihr Anliegen" [(ngModel)]="formData.body" ngModel ></textarea>
    <button class="fe-button" (click)="sendContactForm()" [disabled]="formStatus == 0">Senden</button>
</div>

<p class="errorString" *ngIf="errorString != null && errorString !== ''">{{errorString}}</p>

<div *ngIf="formStatus == 1">
    <p class="sentString">Ihre Nachricht wurde erfolgreich übermittelt.</p>
    <div class="titleUnderline centerDiv"></div>
</div>