import { Component, OnInit } from '@angular/core';
import { RealEstateService } from '../services/real-estate.service';
import { fadeInAnimation } from '../animations/fade-in-animation';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']

})
export class HomeComponent implements OnInit {

  data: any;
  carouselData: string[];
  errorString: any;

  constructor(private service: RealEstateService) { }

  ngOnInit(): void {
    this.init();
  }

  async init() {
    try {
      this.data = await this.service.get();
      this.carouselData = await this.service.getRandomImageUrls();
    } catch (ex) {
      console.error(ex);
      this.errorString = 'Beim laden der Daten ist ein Fehler aufgetreten.';
    }
  }
}
