import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  currentPage: string;
  isAdmin: boolean;

  constructor() {

    const cuUser = Parse.User.current();
    if (cuUser == null) {
      window.open('/', '_self');
    }
    this.isUserIdInRole(cuUser.id, 'admin')
    .then(x => this.isAdmin = x)
    .catch(ex => console.error(ex));
  }

  ngOnInit(): void {
    this.currentPage = 'immobilien';
  }

  logout() {
    Parse.User.logOut().then(() => {
      window.open('/', '_self');
    });
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '250px';
    document.getElementById('main').style.marginLeft = '250px';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
    document.getElementById('main').style.marginLeft = '0';
  }

  async isUserIdInRole(userId, roleName) {
    const User = Parse.Object.extend("_User");
    const Role = Parse.Object.extend("_Role");

    const innerQuery = new Parse.Query(User);
    innerQuery.equalTo("objectId", userId);

    const query = new Parse.Query(Role);
    query.equalTo("name", roleName);
    query.matchesQuery("users", innerQuery);

    const comments = await query.find();

    return comments ? comments.length > 0 : false;
  }


}
