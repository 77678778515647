<div class="">
    <div class="triangleFooter">
    </div>
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>Kontakt</h1>
                </div>
            </div>
        </div>
        <div class="container contactData">
            <div class="row">
                <div class="col-md-4">
                    <p *ngIf="telNumber" class="">TEL: {{telNumber}}

                    </p>
                </div>
                <div class="col-md-4">
                    <p class="">ADRESSE:<br>
                        SESERA AG<br>
                        Güetli 186<br>
                        CH-9428 Walzenhausen</p>
                </div>
                <div class="col-md-4">
                    <p class="">MAIL: <a [href]="'mailto:' + mailAdress">{{mailAdress}}</a></p>
                </div>
            </div>
        </div>

        <div class="container links">
            <div class="row">
                <div class="col-md-2" routerLink="/impressum">
                    <span>Impressum</span>
                </div>
                <div class="col-md-2" routerLink="/datenschutzerklaerung">
                    <span>Datenschutzerklärung</span>
                </div>
            </div>
        </div>

    </div>

</div>