import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { RealEstateService } from '../services/real-estate.service';
import { ContactForm } from '../models/contact-form';

@Component({
  selector: 'app-fe-contact-form',
  templateUrl: './fe-contact-form.component.html',
  styleUrls: ['./fe-contact-form.component.scss'],
})
export class FeContactFormComponent implements OnInit {
  formData: ContactForm;
  errorString: string;
  formStatus: FormStatus;

  constructor(private service: RealEstateService) {
    this.formStatus = FormStatus.normal;
    this.formData = { name: '', mail: '', body: '' } as ContactForm;
  }

  ngOnInit(): void {}

  async sendContactForm() {
    this.errorString = '';
    
    const validationResult = this.validateForm();
    console.log(validationResult);
    if (!validationResult) {
      this.errorString = 'Bitte überprüfen Sie Ihre Eingaben.';
      return;
    }

    this.formStatus = FormStatus.loading;

    this.formData.mail = this.formData.mail.toLocaleLowerCase().split(' ').join('');

    try {
      const GameScore = Parse.Object.extend('ContactForm');
      const formObj = new GameScore();
      this.service.interface2ParseObj(this.formData, formObj);
      await formObj.save();
      this.formStatus = FormStatus.sent;
    } catch (ex) {
      console.error(ex);
      this.errorString =
        'Es ist ein Fehler bei der Übermittlung der Daten aufgetreten. Versuchen Sie es später erneut.';
      this.formStatus = FormStatus.error;
    }
  }

  private validateForm() {
    let returnVal = true;

    for (const key in this.formData) {
      if (
        key !== 'createdAt' &&
        key !== 'updatedAt' &&
        key !== 'objectId' &&
        key !== 'images' &&
        key !== 'coverImage'
      ) {
        if (
          this.formData[key] == null ||
          this.formData[key].split(' ').join('') === ''
        ) {
          returnVal = false;
          break;
        } else if (key == 'mail' && !this.ValidateEmail(this.formData[key])) {
          returnVal = false;
          break;
        }
      }
    }

    return returnVal;
  }

  private ValidateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }
    return false;
  }
}

enum FormStatus {
  loading = 0,
  sent = 1,
  error = 2,
  normal = 3,
}
