import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
    providedIn: 'root',
})
export class ParameterService {

    public static async getParameter(key) {
        const GameScore = Parse.Object.extend('parameter');
        const query = new Parse.Query(GameScore);
        query.equalTo('key', key);
        const param = await query.first();
        if (param == null) {
            return ''
        } else {
            return param.attributes.value;
        }
    }

    public static async getAllParameters() {
        const GameScore = Parse.Object.extend('parameter');
        const query = new Parse.Query(GameScore);
        const params = await query.find();
        return params;
    }

    public static async setParameter(key, value) {
        const GameScore = Parse.Object.extend('parameter');
        const query = new Parse.Query(GameScore);
        query.equalTo('key', key);
        const param = await query.first();
        param.set('value', value);
        await param.save();
    }
}