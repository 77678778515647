export interface ContactForm {
  name: string;
  mail: string;
  body: string;
}

export interface EstateContactForm {
  firstname: string;
  lastname: string;
  mail: string;
  body: string;
}

