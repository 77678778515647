import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'imagePreview'
})
export class ImagePreviewPipe implements PipeTransform {
    async transform(file: File) {
        const reader = new FileReader();
        const returnVal = new Promise((resolve, reject) => {

            reader.onload = (event: any) => {
                resolve(event.target.result);
            };

            reader.onerror = function (e) {
                console.error('Error reading file', e);
                reject(e);
            };
        });
        reader.readAsDataURL(file);

        return await returnVal;
    }
}
