import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { RealEstateComponent } from './real-estate/real-estate.component';
import { FeRealEstateComponent } from './fe-real-estate/fe-real-estate.component';
import { FeContactComponent } from './fe-contact/fe-contact.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeFullscreenCarouselComponent } from './fe-fullscreen-carousel/fe-fullscreen-carousel.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzerklaerungComponent } from './datenschutzerklaerung/datenschutzerklaerung.component';
import { RenterContactComponent } from './renter-contact/renter-contact.component';


export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    data: {animation: 'Home'} 
  },
  {
    path: 'kontakt',
    pathMatch: 'full',
    component: FeContactComponent,
    data: {animation: 'Contact'} 
  },
  {
    path: 'mieter',
    pathMatch: 'full',
    component: RenterContactComponent,
    data: {animation: 'Contact'} 
  },
  {
    path: 'impressum',
    pathMatch: 'full',
    component: ImpressumComponent,
    data: {animation: 'Contact'} 
  },
  {
    path: 'datenschutzerklaerung',
    pathMatch: 'full',
    component: DatenschutzerklaerungComponent,
    data: {animation: 'Contact'} 
  },
  {
    path: 'immobilie/:id',
    pathMatch: 'full',
    component: FeRealEstateComponent,
    data: {animation: 'RealEstate'} 
  },
  {
    path: 'immobilie/:id/bilder',
    pathMatch: 'full',
    component: FeFullscreenCarouselComponent,
    data: {animation: 'RealEstateImages'} 
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'admin',
    pathMatch: 'full',
    component: AdminComponent,
    data: {animation: 'Admin'} 
  },
  {
    path: 'admin/immobilie/:id',
    pathMatch: 'full',
    component: RealEstateComponent,
  },
  {
    path: '*',
    pathMatch: 'full',
    component: HomeComponent,
  }];
/*
@NgModule({
  imports: [RouterModule.forRoot(routes), BrowserAnimationsModule],
  exports: [RouterModule, BrowserAnimationsModule]
})
export class AppRoutingModule { }*/
