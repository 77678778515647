import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginInProgress: boolean;

  username: string;
  password: string;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.loginInProgress = false;

    const currentUser = Parse.User.current();
    if (currentUser) {
      this.router.navigateByUrl('/admin');
    }
  }

  async login() {

    try {

      this.loginInProgress = true;

      const user = await Parse.User.logIn(this.username, this.password);
      window.open('/admin', '_self'); // not so nice

    } catch (ex) {
      this.loginInProgress = false;
      alert('Benutzername oder Passwort falsch');
      console.log(ex);
    }

  }
}
