import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root',
})
export class RealEstateService {
  private data: any;

  constructor() {}

  public async get() {

    const cuUser = Parse.User.current();

    if (!this.data || cuUser) {
      const GameScore = Parse.Object.extend('RealEstate');
      const query = new Parse.Query(GameScore);
      query.include('coverImage');
      query.equalTo('status', true);
      query.limit(100000);
      this.data = await query.find();
    }
    return this.data;
  }

  public async getById(id: string) {
    const estates = await this.get();
    return estates.find((x) => x.id === id);
  }

  public async getImageUrlFromEstate(estateId: string): Promise<string[]> {
    const estate = await this.getById(estateId);
    const relation = estate.relation('images');

    // generate a query based on that relation
    const query = relation.query();
    const estateImages = await query.find();

    return estateImages.map((x) => x.get('file').url());
  }

  public async getRandomImageUrls(limit = 5) {
    const GameScore = Parse.Object.extend('Image');
    const query = new Parse.Query(GameScore);
    query.limit(limit);
    const imageObjs = await query.find();
    return imageObjs.map((x) => x.get('file').url());
  }

  public interface2ParseObj(interf, parseObj) {
    // tslint:disable-next-line:forin
    for (const key in interf) {
      if (
        key !== 'createdAt' &&
        key !== 'updatedAt' &&
        key !== 'objectId' &&
        key !== 'images' &&
        key !== 'coverImage'
      ) {
        parseObj.set(key, interf[key]);
      }
    }
    return parseObj;
  }

  public isCurrentUser() {
    const cuUser = Parse.User.current();
    return cuUser != null;
  }

  public parseObj2Interface(parseObj) {
    const returnVal = {};
    // tslint:disable-next-line:forin
    for (const key in parseObj.attributes) {
      returnVal[key] = parseObj.attributes[key];
    }
    // tslint:disable-next-line:no-string-literal
    returnVal['objectId'] = parseObj.id;
    return returnVal;
  }
}
