import { trigger, animate, transition, style, state, query, group } from '@angular/animations';



export const fadeInAnimation =
    trigger('routeAnimations', [
        transition('* => Contact', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0, top: -10 }),
                    // animation and styles at end of transition
                    animate('0.25s ease-in-out', style({ opacity: 1, top: 0 })),
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 0 }),
                    // animation and styles at end of transition
                    //animate('0.2s ease-in-out', style({ opacity: 0 })),
                ], { optional: true }),
            ])
        ]),
        transition('* => Home', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0, top: 10}),
                    // animation and styles at end of transition
                    animate('0.25s ease-in-out', style({ opacity: 1, top: 0 })),
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 0 }),
                ], { optional: true }),
            ])
        ]),
        transition('* => RealEstate', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0, top: -10 }),
                    // animation and styles at end of transition
                    animate('0.25s ease-in-out', style({ opacity: 1, top: 0 })),
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 0 }), ], { optional: true }),
            ])
        ]),
        transition('* => Admin', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0 }),
                    // animation and styles at end of transition
                    animate('0.25s ease-in-out', style({ opacity: 1 })),
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 0 }), ], { optional: true }),
            ])
        ]),
        transition('* => RealEstateImages', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ opacity: 0 }),
                    // animation and styles at end of transition
                    animate('0.25s ease-in-out', style({ opacity: 1 })),
                ], { optional: true }),
                query(':leave', [
                    style({ opacity: 0 }), ], { optional: true }),
            ])
        ]),
    ]);
    /*
export const fadeInAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('routeAnimations', [
    // ...
    state('open', style({
      height: '200px',
      opacity: 1,
      backgroundColor: 'yellow'
    })),
    state('closed', style({
      height: '100px',
      opacity: 0.5,
      backgroundColor: 'green'
    })),
    transition('* => closed', [
      animate('1s')
    ]),
    transition('* => open', [
      animate('0.5s')
    ])
  ]);/*
  trigger('fadeInAnimation', [
    // route 'enter' transition
    transition(':enter', [
      // css styles at start of transition
      style({ opacity: 0 }),

      // animation and styles at end of transition
      animate('.2s', style({ opacity: 1 })),
    ]),
    /*transition(':leave', [

            // css styles at start of transition
            style({ opacity: 1 }),

            // animation and styles at end of transition
            animate('.2s', style({ opacity: 0 }))
        ])*/
  //]);
