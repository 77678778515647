import { Component, OnInit } from '@angular/core';
import { RealEstate } from '../models/real-estate';
import { ActivatedRoute, Router } from '@angular/router';
import { RealEstateService } from '../services/real-estate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { fadeInAnimation } from '../animations/fade-in-animation';

@Component({
  selector: 'app-fe-fullscreen-carousel',
  templateUrl: './fe-fullscreen-carousel.component.html',
  styleUrls: ['./fe-fullscreen-carousel.component.scss']
})
export class FeFullscreenCarouselComponent implements OnInit {
  
  data: RealEstate;
  estateImagesUrls: string[];

  realEstateId: string;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: RealEstateService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    try {

      this.route.params.subscribe(async (params) => {
        
        this.realEstateId = params.id;
        this.estateImagesUrls = await this.service.getImageUrlFromEstate(params.id);
        if (this.estateImagesUrls == null || this.estateImagesUrls.length == 0) {
          throw Error('Es wurden keine Bilder gefunden.');
        }
        
      });
    } catch (ex) {
      console.error(ex);
      this.errorMessage =
        'Daten konten nicht geladen werden. Versuchen Sie es später erneut.';
    }
  }

}
