<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">Benutzer</a>
    <div class=" navbar-collapse" id="navbarSupportedContent">
      
        <form class="form-inline my-2 my-lg-0">
            <button (click)="addUser()" class="btn btn-outline-secondary my-2 my-sm-0"
                type="button">Neuer Benutzer</button>
        </form>
    </div>
</nav>
<div class="navSpacer">
    <div></div>
</div>
<div class="content">
    <app-loader3 *ngIf="!data"></app-loader3>
    <p *ngIf="data && data.length == 0" class="noitemsFound">Zurzeit gibt es keine Benutzer.</p>

    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col-12" *ngFor="let item of data">
                <div>

                    <div class="card">
                        <div class="card-body">
                            <p class="card-text">Benutzer: {{item.attributes.username}}
                                 </p>

                            <a class="btn btn-outline-primary" (click)="changePassword(item)">Passwort zurücksetzen</a>&nbsp;&nbsp;
                            <a class="btn btn-outline-danger" (click)="deleteUser(item)">Benutzer löschen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>