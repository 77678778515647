<div class="container" *ngIf="errorString != null && errorString !== ''">
  <div class="blockSpaceTop backgrColor1">
    <div></div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        {{errorString}}
      </div>
    </div>
  </div>
</div>


<div *ngIf="!data" class="loader">

  <div class="navPlaceholder">
    <div></div>
  </div>
  <app-loader3></app-loader3>
</div>

<div *ngIf="data">

  <div class="navPlaceholder">
    <div></div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <!--img src="/assets/images/sesera-logo.svg" class="seseraLogo" alt=""-->
        <h1 class="seseraMainTitle">Sesera immobilien</h1>
        <h2 class="seseraMainSubtitle">Ihr Partner für Mietwohnungen in der Ostschweiz</h2>
      </div>
    </div>
  </div>

  <div class="triangleBackgr1"></div>
  <div class="backgrColor1 blockSpaceTop">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="pageTitle titleColor2">Alle Immobilien</h1>
        </div>
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
          <p class="subtitle titleColor2">Nachfolgend finden Sie derzeit verfügbare Objekte in der Region.
            Gerne stehen wir
            Ihnen bei
            Rückfragen telefonisch oder per E-Mail zur Verfügung.</p>
        </div>
        <div class="col-md-2">
        </div>
      </div>
    </div>
    <div class="container ">
      <div class="row">
        <div class="col-12" *ngIf="data.length == 0">
          <p class="subtitle titleColor2">Zurzeit gibt es keine verfügbaren Immobilien.</p>
        </div>
        <div class="col-md-6 col-12 col-lg-4" *ngFor="let item of data">
          <div class="estate">
            <img *ngIf="item.get('coverImage')" [src]="item.get('coverImage').get('file').url()" width="100%" />
            <div class="information">
              <h3>{{item.attributes.title}}</h3>
              <h4>{{item.attributes.subtitle}}</h4>
              <h4>{{item.attributes.rooms}} Zimmer / {{item.attributes.livingSpace}}</h4>
              <div class="centerAlign">
                <app-button1 [routerLink]="['/immobilie/' + item.id]" buttonText="Details">
                </app-button1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="blockSpaceTop">
      <div></div>
    </div>

  </div>

  <div class="blockSpaceTop backgrColor1">


  </div>

  <div class="triangleBackgr2">
  </div>


  <div class="container blockSpaceTop">
    <div class="row">
      <div class="col-md-2">
      </div>
      <div class="col-md-8">
        <h1 class="pageTitle">Kontakt</h1>
        <p class="subtitle">Kontaktieren Sie uns, falls Sie Fragen zu unseren Immobilien haben. Wir sind gerne für Sie
          da.</p>
      </div>
      <div class="col-md-2">
      </div>

      <div class="col-md-2">
      </div>
      <div class="col-md-8">
        <app-fe-contact-form></app-fe-contact-form>
      </div>
      <div class="col-md-2">
      </div>
    </div>
  </div>

  <div class="blockSpaceTop">
    <div></div>
  </div>

  <app-fe-contact-footer></app-fe-contact-footer>
</div>