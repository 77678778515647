import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';

@Component({
  selector: 'app-real-estates',
  templateUrl: './real-estates.component.html',
  styleUrls: ['./real-estates.component.scss']
})
export class RealEstatesComponent implements OnInit {

  data: any[];
  constructor() { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    try {

      const GameScore = Parse.Object.extend('RealEstate');
      const query = new Parse.Query(GameScore);
      query.include('coverImage');
      query.limit(100000);
      this.data = await query.find();

    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

}
