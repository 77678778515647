<div class="container" *ngIf="errorMessage != null && errorMessage !== ''">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert">
                {{errorMessage}}
            </div>
        </div>
    </div>
</div>


<div>

    <div class="blockSpaceTop backgrColor1">
        <div></div>
    </div>
    <div class="blockSpaceTop backgrColor1">

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="pageTitle">Kontakt</h1>
                    <p class="subtitle">Kontaktieren Sie uns, falls Sie Fragen zu unseren Immobilien haben. Wir sind gerne für
                        Sie
                        da.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="triangleBackgr2">
    </div>

    <div class="container blockSpaceTop">
        <div class="row">

            <div class="col-md-2">
            </div>
            <div class="col-md-8">
                <h3 class="pageTitle">Kontaktformular</h3>
                <app-fe-contact-form></app-fe-contact-form>
            </div>
            <div class="col-md-2">
            </div>
        </div>
    </div>

    
    <div class="blockSpaceTop">
        <div></div>
    </div>
</div>

<app-fe-contact-footer></app-fe-contact-footer>