import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { Button1Component } from './button1/button1.component';
import { HomeComponent } from './home/home.component';
import { Loader1Component } from './loader1/loader1.component';
import { Loader2Component } from './loader2/loader2.component';
import { Loader3Component } from './loader3/loader3.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaComponent } from './media/media.component';
import { NgxFileHelpersModule } from 'ngx-file-helpers';
import { RealEstatesComponent } from './real-estates/real-estates.component';
import { RealEstateComponent } from './real-estate/real-estate.component';
import { CarouselComponent } from './carousel/carousel.component';
import { FeRealEstateComponent } from './fe-real-estate/fe-real-estate.component';
import { FeContactFooterComponent } from './fe-contact-footer/fe-contact-footer.component';
import { FeContactComponent } from './fe-contact/fe-contact.component';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FeContactFormComponent } from './fe-contact-form/fe-contact-form.component';
import { FeFullscreenCarouselComponent } from './fe-fullscreen-carousel/fe-fullscreen-carousel.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ImpressumComponent } from './impressum/impressum.component';
import { ParameterComponent } from './parameter/parameter.component';
import { UsersComponent } from './users/users.component';
import { FeNavMobileComponent } from './fe-nav-mobile/fe-nav-mobile.component';
import { DatenschutzerklaerungComponent } from './datenschutzerklaerung/datenschutzerklaerung.component';
import { FeEstateContactFormComponent } from './fe-estate-contact-form/fe-estate-contact-form.component';
import { RenterContactComponent } from './renter-contact/renter-contact.component';
import { ImagePreviewPipe } from './pipes/image-preview.pipe';


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    Button1Component,
    HomeComponent,
    Loader1Component,
    Loader2Component,
    Loader3Component,
    LoginComponent,
    AdminComponent,
    MediaComponent,
    RealEstatesComponent,
    RealEstateComponent,
    CarouselComponent,
    FeRealEstateComponent,
    FeContactFooterComponent,
    FeContactComponent,
    FeContactFormComponent,
    FeFullscreenCarouselComponent,
    ImpressumComponent,
    ParameterComponent,
    UsersComponent,
    FeNavMobileComponent,
    DatenschutzerklaerungComponent,
    FeEstateContactFormComponent,
    RenterContactComponent,
    ImagePreviewPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    FormsModule,
    NgxFileHelpersModule,
    NgbModule,
    CKEditorModule,
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
