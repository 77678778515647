import { Component, OnInit } from '@angular/core';
import { ParameterService } from '../services/parameter.service';

@Component({
  selector: 'app-fe-contact-footer',
  templateUrl: './fe-contact-footer.component.html',
  styleUrls: ['./fe-contact-footer.component.scss']
})
export class FeContactFooterComponent implements OnInit {

  telNumber: string;
  mailAdress: string;

  constructor() { }

  async ngOnInit() {
    this.telNumber = await ParameterService.getParameter('telephoneNumber');
    this.mailAdress = await ParameterService.getParameter('mainMailAddress');
  }

}
