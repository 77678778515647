<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">Immobilien</a>
    <div class=" navbar-collapse" id="navbarSupportedContent">
      
        <form class="form-inline my-2 my-lg-0">
            <button routerLink="/admin/immobilie/new" class="btn btn-outline-secondary my-2 my-sm-0"
                type="button">Hinzufügen</button>
        </form>
    </div>
</nav>

<div class="navSpacer"><div></div></div>
<div class="content">
    <app-loader3 *ngIf="!data"></app-loader3>
    <p *ngIf="data && data.length == 0" class="noitemsFound">Zurzeit gibt es keine Medien.</p>

    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of data">
                <div>

                    <div class="card" style="width: 18rem;">
                        <img *ngIf="item.get('coverImage')" class="card-img-top" [src]="item.get('coverImage').get('file').url()" alt="Card image cap">
                        <div class="card-body">
                            <p class="card-text">{{item.attributes.title}}<br />{{item.attributes.subtitle}}<br />
                                <span style="color: gray;">Status: <span [ngStyle]="{'color': item.attributes.status ? 'green' : 'red'}">{{item.attributes.status ? 'veröffentlicht' : 'verborgen'}}</span></span></p>
                            <a class="btn btn-outline-primary" [routerLink]="['/admin/immobilie/' + item.id]">Bearbeiten</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>