<div class="fullscreen">
    <p *ngIf="errorMessage" class="errorMessage">{{errorMessage}}</p>

    <nav id="header" class="navbar navbar-expand-lg navbar-light bg-light">
        <span class="material-icons" [routerLink]="['/immobilie/' + realEstateId]">
            arrow_back
        </span>
      
      
          <ul class="navbar-nav mr-auto ml-auto">
            <li class="nav-item">
              <a class="nav-link">Bilder</a>
            </li>
          </ul>
          <!--span class="material-icons">
            save_alt
        </span-->&nbsp;&nbsp;&nbsp;
      </nav>

    <app-carousel [imageUrls]="estateImagesUrls" [timeout]="3000" heightPx="90vh" cropImage2Fit="false"></app-carousel>

</div>