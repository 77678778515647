<ngb-carousel #carousel [interval]="timeout" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
    <ng-template ngbSlide *ngFor="let img of imageUrls; index as i">

        <div class="picsum-img-wrapper">
            <div *ngIf="cropImage2Fit" class="divBackgrImage carouselImage"
                [ngStyle]="{'background-image': 'url(' + img + ')', 'height': heightPx}">
                <div></div>
            </div>
        </div>

        <div *ngIf="!cropImage2Fit" [ngStyle]="{'height': heightPx}">
            <div class="imgWrapper">
                <span class="align-middle"><img [src]="img" class="carouselImg align-middle" /></span>
            </div>
        </div>

    </ng-template>
</ngb-carousel>