import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-fe-nav-mobile',
  templateUrl: './fe-nav-mobile.component.html',
  styleUrls: ['./fe-nav-mobile.component.scss']
})
export class FeNavMobileComponent implements OnInit {

  
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  openSite(url) {
    this.onClose.emit([url]);
  }

}
