<div class="fullscreen ">
    <div class="backgrColor1">
        <div></div>
    </div>
    <div class="backgrColor1">

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <img src="/assets/images/sesera-logo.svg" class="seseraLogo" alt="">
                </div>
            </div>
        </div>
    </div>

    <div class="">
    </div>

    <div class="container ">
        <div class="row">
            <div class="col-12">
                <h1 class="seseraMainTitle firstMenuItem" (click)="openSite('/')">Immobilien</h1>
                <h1 class="seseraMainTitle" (click)="openSite('/mieter')">Für Mieter</h1>
                <h1 class="seseraMainTitle" (click)="openSite('/kontakt')">Kontakt</h1>
            </div>
        </div>
    </div>
</div>