import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as Parse from 'parse';
import { RealEstateService } from '../services/real-estate.service';

enum FormStatus {
  initial,
  sending,
  sent,
  error

}

@Component({
  selector: 'app-renter-contact',
  templateUrl: './renter-contact.component.html',
  styleUrls: ['./renter-contact.component.scss']
})
export class RenterContactComponent implements OnInit {

  FormStatus = FormStatus;
  formStatus = FormStatus.initial;

  selectedFiles: File[] = [];

  //  ngForm = NgForm;

  constructor(private realEstateService: RealEstateService) { }

  ngOnInit(): void {
  }

  onFileChange(event: any) {
    const newFiles = Array.from(event.target.files) as File[];
    if (this.selectedFiles.length + newFiles.length > 10) {
      alert('Es können maximal 10 Dateien angehängt werden.');
      return;
    }
    const tooLargeFiles = newFiles.filter(x => x.size > 30 * 1024 * 1024);
    if (tooLargeFiles.length) {
      alert('Die Dateien dürfen nicht grösser als 30 MB sein.');
      return;
    }
    this.selectedFiles = [...this.selectedFiles, ...newFiles] as File[];
    event.target.value = null;
  }

  removeFile(file: File) {
    this.selectedFiles = this.selectedFiles.filter(x => x !== file);
  }

  public async sendData(f?: NgForm) {
    if (!f || !f.valid) {
      alert('Bitte überprüfen Sie das Formular und füllen Sie alle Pflichtfelder aus.');
      return;
    }

    this.formStatus = FormStatus.sending;
    try {
      const GameScore = Parse.Object.extend('RenterContactForm');
      const formObj = new GameScore();
      this.realEstateService.interface2ParseObj(f.value, formObj);

      const filesAsParseObject = this.selectedFiles.map(file => {
        const RenterContactFormImage = Parse.Object.extend("RenterContactFormImage");
        const renterImage = new RenterContactFormImage();
        renterImage.set('file', new Parse.File(file.name, file, file.type));
        return renterImage;
      });

      const savedParseObjectImages = await Parse.Object.saveAll(filesAsParseObject);
      console.log(savedParseObjectImages)

      const relation = formObj.relation("images");
      savedParseObjectImages.forEach(x => relation.add(x));

      await formObj.save();
      this.formStatus = FormStatus.sent;
    } catch (ex) {
      this.formStatus = FormStatus.error;
      console.error(ex);
    }
    return false;
  }



}
