import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import * as Parse from 'parse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  showAdmin: boolean;
  showMobileNav: boolean;

  constructor(private route: Router) {
    route.events.subscribe((val) => {
      window.scrollTo(0, 0);
      
      $('.nav-link').removeClass('nav-link-active');
      if (this.route.url.indexOf('kontakt') !== -1) {
        $('#kontakt').addClass('nav-link-active');
      } else if (this.route.url.indexOf('immobilie') !== -1) {
        // Nothing
      } else if (this.route.url.includes('mieter')) {
        $('#mieter').addClass('nav-link-active');
      } else {
        $('#immobilie').addClass('nav-link-active');
      }
    });
  }

  ngOnInit(): void {
    this.showAdmin = !!Parse.User.current();
    // DEFINITIC NICHT "BEST PRACTISE"
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll > 0) {
        $('#header').addClass('scrollActive');
      } else {
        $('#header').removeClass('scrollActive');
      }
    });
  }

  openSite(url) {    
    this.showMobileNav = false;
    this.route.navigateByUrl(url[0]);
  }
}
