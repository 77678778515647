import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import * as Parse from 'parse';
import { ReadFile } from 'ngx-file-helpers';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {


  @Input() windowType: string; // multiplechooser / editor

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onItemsChoosed: EventEmitter<any> = new EventEmitter();

  chosenItems: any[];

  data: any;
  files2Upload: ReadFile[];
  uploadInProgress: boolean;
  constructor() { }

  ngOnInit(): void {
    this.files2Upload = [];
    this.chosenItems = [];
    this.uploadInProgress = false;
    this.load();
  }

  async load() {
    try {
      const GameScore = Parse.Object.extend('Image');
      const query = new Parse.Query(GameScore);
      query.descending('createdAt');
      query.limit(100000000);
      this.data = await query.find();
    } catch (ex) {
      console.error(ex);
      alert('Beim laden der Daten ist ein Fehler aufgetreten.');
    }
  }

  async loadFiles(file: ReadFile) {
    try {


      if (!file.type.includes('image')) {
        alert('Falsches Dateiformat.');
        return;
      }

      this.files2Upload.push(file);


    } catch (ex) {
      console.error(ex);
      alert('Es ist ein Fehler aufgetreten.');
    }
  }

  async uploadLoadedFiles() {
    try {
      this.uploadInProgress = true;

      const awaitables = [];

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.files2Upload.length; i++) {
        const file = this.files2Upload[i];

        const GameScore = Parse.Object.extend('Image');
        const gameScore = new GameScore();

        const parseFile = new Parse.File(file.name, file.underlyingFile);

        gameScore.set('name', file.name);
        gameScore.set('file', parseFile);
        gameScore.set('type', file.type);
        gameScore.set('size', file.size);

        awaitables.push(gameScore.save() as Promise<any>);

      }

      const bar = await Promise.all(awaitables);

      await this.load();
      this.files2Upload = [];

    } catch (ex) {
      console.error(ex);
      alert('Es ist ein Fehler aufgetreten.');
    }
    this.uploadInProgress = false;
  }

  async deleteFile(item: any) {
    try {

      this.uploadInProgress = true;

      if (!confirm('Möchten Sie das Bild wirklich löschen?')) {
        return;
      }

      await item.destroy();

      await this.load();
      this.uploadInProgress = false;

    } catch (ex) {
      console.error(ex);
      alert('Es ist ein Fehler aufgetreten.');
    }
  }



  addItem2ChosenOnes(item: any) {
    this.chosenItems.push(item);
    item.set('chosen', true);
  }

  removeItemFromChosenOnes(item: any) {
    this.chosenItems.filter(x => x.id !== item.id);
    item.set('chosen', null);
  }

  setChosenItems(): void {
    this.chosenItems.forEach(x => x.set('chosen', null));
    this.onItemsChoosed.emit([this.chosenItems]);
    this.chosenItems = [];
  }

  exitWindow() {
    this.chosenItems.forEach(x => x.set('chosen', null));
    this.onItemsChoosed.emit([null]);

  }
}
