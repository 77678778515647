import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../animations/fade-in-animation';

@Component({
  selector: 'app-fe-contact',
  templateUrl: './fe-contact.component.html',
  styleUrls: ['./fe-contact.component.scss']
})
export class FeContactComponent implements OnInit {

  errorMessage: string; 

  constructor() { }

  ngOnInit(): void {
  }

}
