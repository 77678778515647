<div class="container" *ngIf="errorMessage != null && errorMessage !== ''">
  <div class="blockSpaceTop backgrColor1">
    <div></div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger" role="alert">
        {{errorMessage}}
      </div>
    </div>
  </div>
</div>

<div *ngIf="!data && (errorMessage == null ||  errorMessage== '')" class="loader">
  <div class="blockSpaceTop backgrColor1">
    <div></div>
  </div>
  <app-loader3></app-loader3>
</div>

<div *ngIf="data">

  <div class="blockSpaceTop backgrColor1">
    <div></div>
  </div>
  <div class="blockSpaceTop backgrColor1">

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="pageTitle">{{data.title}}</h1>
          <p class="subtitle">{{data.subtitle}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="overlayContainer">
    <div class="box divBackgrImage" [ngStyle]="{'background-image': 'url(' + data.coverImage.get('file').url() + ')'}">
      <div></div>
    </div>
    <div class="box stack-top">
      <div class="triangleBackgr2">
      </div>
    </div>
  </div>

  <!-- Header Bar -->

  <div class="blockSpaceTop">
    <div></div>
  </div>

  <div class="blockSpaceTop d-none d-sm-none d-md-block d-lg-block">
    <div></div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <app-carousel [imageUrls]="estateImagesUrls" [timeout]="3000" cropImage2Fit="true"></app-carousel>
        <p [routerLink]="['/immobilie/' + data.objectId + '/bilder']" class="fullscreenBtn">
          <span class="material-icons"> fullscreen </span> <br />Vollbild</p>
        <div class="blockSmallSpaceTop">
          <div></div>
        </div>
      </div>
      <div class="col-md-7">
        <h4>Beschreibung</h4>
        <div class="titleUnderline">
          <div></div>
        </div>
        <p class="txt" [innerHTML]="data.description"></p>
        <p class="txt" *ngIf="data.availability && data.availability != ''"><strong>Verfügbarkeit:</strong>
          {{data.availability}}</p>

      </div>
    </div>
  </div>


  <div class="blockSmallSpaceTop">
    <div></div>
  </div>

  <div class="container">
    <div class="row quickFacts">

      <div class="col-md-4">
        <div class="roundedBullet">
          <h5>Bruttomiete</h5>
          <p>{{data.bruttomiete}}</p>
        </div>
        <div class="blockSmallSpaceTop">
          <div></div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="roundedBullet">
          <h5>Wohnraum</h5>
          <p>{{data.livingSpace}}</p>
        </div>
        <div class="blockSmallSpaceTop">
          <div></div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="roundedBullet">
          <h5>Zimmer</h5>
          <p>{{data.rooms}}</p>
        </div>
        <div class="blockSmallSpaceTop">
          <div></div>
        </div>
      </div>
    </div>
  </div>


  <div class="blockSmallSpaceTop d-none d-sm-none d-md-block d-lg-block">
    <div></div>
  </div>

  <div class="container">
    <div class="row dataRow">
      <div class="col-12">
        <h4>Lageplan</h4>
        <div class="titleUnderline">
          <div></div>
        </div>
        <iframe *ngIf="mapsUrl" class="map" frameborder="0" style="border:0" [src]="mapsUrl" allowfullscreen></iframe>
      </div>
    </div>
  </div>


  <div class="blockSmallSpaceTop d-none d-sm-none d-md-block d-lg-block">
    <div></div>
  </div>

  <div class="container">
    <div class="row dataRow">
      
      <div class="col-12">
        <h4>SESERA AG Kontaktieren</h4>
        <div class="titleUnderline">
          <div></div>
        </div>
        
        <app-fe-estate-contact-form ></app-fe-estate-contact-form>
      </div>
    </div>
  </div>

  <div class="immoscoutBtn blockSpaceTop" *ngIf="data.externalAdUrl && data.externalAdUrl != ''">
    <button class="fe-button2"><a [href]="data.externalAdUrl">auf Immoscout24 ansehen</a></button>
    <button class="fe-button2" *ngIf="isCurrentUser" (click)="edit()"><a>Bearbeiten</a></button>
  </div>

  <div class="blockSpaceTop">
    <div></div>
  </div>
  <app-fe-contact-footer></app-fe-contact-footer>
</div>
