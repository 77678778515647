<!--div class="container" *ngIf="errorMessage != null && errorMessage !== ''">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-danger" role="alert">
                {{errorMessage}}
            </div>
        </div>
    </div>
</div-->


<div>

    <div class="blockSpaceTop backgrColor1">
        <div></div>
    </div>
    <div class="blockSpaceTop backgrColor1">

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="pageTitle">Für Mieter</h1>
                    <p class="subtitle">Haben Sie ein Anliegen oder möchten einen Schaden melden? Gerne können Sie uns
                        über das untenstehende Formular kontaktieren.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="triangleBackgr2">
    </div>

    <div class="container blockSpaceTop">
        <div class="row">

            <div class="col-md-2">
            </div>
            <div class="col-md-8">
                <h3 class="pageTitle">Kontaktformular</h3>

                <div>

                    <form (ngSubmit)="sendData(loginForm)" #loginForm="ngForm" *ngIf="formStatus !== FormStatus.sent && formStatus !== FormStatus.sending">
                        <div class="form-group">
                            <label> Vorname* </label>
                            <input class="fe-input" placeholder="Vorname" type="text" name="firstName" ngModel required>
                            <div class="error-msg"
                                *ngIf="loginForm.controls['firstName']?.invalid && (loginForm.controls['firstName']?.dirty || loginForm.controls['firstName']?.touched)">
                                Bitte geben Sie einen Vornamen ein.
                            </div>
                        </div>

                        <div class="form-group">
                            <label> Nachname* </label>
                            <input class="fe-input" placeholder="Nachname" type="text" name="lastName" ngModel required>
                            <div class="error-msg"
                                *ngIf="loginForm.controls['lastName']?.invalid && (loginForm.controls['lastName']?.dirty || loginForm.controls['lastName']?.touched)">
                                Bitte geben Sie einen Nachnamen ein.
                            </div>
                        </div>

                        <div class="form-group">
                            <label> E-Mail* </label>
                            <input class="fe-input" placeholder="E-Mail" type="email" name="mail" ngModel required>
                            <div class="error-msg"
                                *ngIf="loginForm.controls['mail']?.invalid && (loginForm.controls['mail']?.dirty || loginForm.controls['mail']?.touched)">
                                Bitte geben Sie Ihre E-Mail ein.
                            </div>
                        </div>


                        <div class="form-group">
                            <label> Tel. Nr.* </label>
                            <input class="fe-input" placeholder="Tel. Nr." type="tel" name="phone" ngModel required>
                            <div class="error-msg"
                                *ngIf="loginForm.controls['tel']?.invalid && (loginForm.controls['tel']?.dirty || loginForm.controls['tel']?.touched)">
                                Bitte geben Sie Ihre aktuelle Telefonnummer ein.
                            </div>
                        </div>

                        <div class="form-group">
                            <label> Adresse* </label>
                            <input class="fe-input" placeholder="Adresse" type="text" name="address" ngModel required>
                            <div class="error-msg"
                                *ngIf="loginForm.controls['address']?.invalid && (loginForm.controls['address']?.dirty || loginForm.controls['address']?.touched)">
                                Bitte geben Sie eine Adresse ein.
                            </div>
                        </div>

                        <div class="flex-container">
                            <div class="box1 form-group">
                                <label> PLZ* </label>
                                <input class="fe-input" placeholder="PLZ" type="number" min="1000" max="9999"
                                    name="postalCode" ngModel required>
                                <div class="error-msg"
                                    *ngIf="loginForm.controls['postalCode']?.invalid && (loginForm.controls['postalCode']?.dirty || loginForm.controls['postalCode']?.touched)">
                                    Bitte geben Sie eine Postleitzahl ein.
                                </div>
                            </div>

                            <div class="box2 form-group">
                                <label> Ort* </label>
                                <input class="fe-input" placeholder="Ort" type="text" name="place" ngModel required>
                                <div class="error-msg"
                                    *ngIf="loginForm.controls['place']?.invalid && (loginForm.controls['place']?.dirty || loginForm.controls['place']?.touched)">
                                    Bitte geben Sie einen Ort ein.
                                </div>
                            </div>

                        </div>
                        <div class="form-group">
                            <label> WG-Nummer (falls bekannt) </label>
                            <input class="fe-input" placeholder="WG-Nummer" type="text" name="wgNumber" ngModel>
                        </div>

                        <div class="form-group">
                            <label> Problembeschreibung* </label>
                            <textarea class="fe-input" placeholder="Bitte Beschreiben Sie Ihr Problem so genau wie möglich sodass wir Ihnen schnell helfen können. Fügen Sie allfällige Bilder hinzu."
                             name="description" ngModel required
                                rows="10"></textarea>
                            <div class="error-msg"
                                *ngIf="loginForm.controls['description'].invalid && (loginForm.controls['description'].dirty || loginForm.controls['description'].touched)">
                                Bitte geben Sie eine Beschreibung ein.
                            </div>
                        </div>


                        <div class="form-group">
                            <label> Bilder anhängen </label><br>
                            <input type="file" (change)="onFileChange($event)" accept="image/*" multiple >
                        </div>

                        <div class="image-preview-grid">
                            <div *ngFor="let file of selectedFiles" class="image-preview">
                                <img [src]="file | imagePreview | async" alt="Vorschau" />
                                <br>
                                <button class="fe-button2" (click)="removeFile(file)">Entfernen</button>
                            </div>
                        </div>

                        <button class="fe-button" type="submit">Senden</button>
                    </form>
                    <div *ngIf="formStatus === FormStatus.sending" style="margin-top: 3rem;">
                        <app-loader3></app-loader3>
                    </div>
                </div>

                <p class="errorString" *ngIf="formStatus === FormStatus.error">Es ist ein Fehler aufgetreten, versuchen Sie es später erneut.</p>

                <div *ngIf="formStatus === FormStatus.sent">
                    <p class="sentString">Ihre Nachricht wurde erfolgreich übermittelt. Sie sollten in den nächsten Minuten eine Bestätigung per E-Mail erhalten.</p>
                    <div class="titleUnderline centerDiv"></div>
                </div>
            </div>
            <div class="col-md-2">
            </div>
        </div>
    </div>


    <div class="blockSpaceTop">
        <div></div>
    </div>
</div>

<app-fe-contact-footer></app-fe-contact-footer>