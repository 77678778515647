<div class="container">
    <div class="row">
        <div class="col-12">
            <div class="loginContainer">
            <h1>Login</h1>

            <div class="form-group">
                <label for="exampleInputEmail1">Benutzername</label>
                <input type="text" [(ngModel)]="username" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Benutzername">
            </div>
            <div class="form-group">
                <label for="exampleInputPassword1">Passwort</label>
                <input type="password" [(ngModel)]="password" class="form-control" id="exampleInputPassword1" placeholder="Passwort" (keyup.enter)="login()">
            </div>

            <button type="button" class="btn btn-primary" [disabled]="loginInProgress" (click)="login()">Login</button>
        </div>
        </div>
    </div>
</div>