

<div id="mySidenav" class="sidenav">
  <nav class="navbar navbar-expand-lg navbar-light bg-light" >
    <a class="navbar-brand">
      <img src="/assets/images/sesera-logo.svg" class="navImage"
        alt="">
    </a>
  
    
</nav>
    <div (click)="currentPage = 'immobilien'">Immobilien</div>
    <div (click)="currentPage = 'mediaCenter'">Media Center</div>
    <div *ngIf="isAdmin == true" (click)="currentPage = 'parameter'">Parameter</div>
    <div *ngIf="isAdmin == true" (click)="currentPage = 'user'">Benutzer</div>
    <div routerLink="/">Website</div>
    <div (click)="logout()">Logout</div>
  </div>
  
  <div id="main">
    <app-real-estates *ngIf="currentPage == 'immobilien'"></app-real-estates>
    <app-media *ngIf="currentPage == 'mediaCenter'"></app-media>
    <app-parameter *ngIf="currentPage == 'parameter'"></app-parameter>
    <app-users *ngIf="currentPage == 'user'"></app-users>
  </div>

