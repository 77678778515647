<div>

    <div class="blockSpaceTop backgrColor1">
        <div></div>
    </div>
    <div class="blockSpaceTop backgrColor1">

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="pageTitle">Impressum</h1>
                    <p class="subtitle"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="triangleBackgr2">
    </div>

    <div class="container blockSpaceTop">
        <div class="row">

            <div class="col-md-2">
            </div>
            <div class="col-md-8">

                <h3>Kontakt-Adresse</h3>
                <p>
                    SESERA AG<br />
                    Im Hard 1663<br />
                    CH-9434 Au SG</p>
                <p>Diese Website wurde von <a href="https://idevop.ch">idevop.ch</a> erstellt.</p>

                <br />
                <h3>Haftungsausschluss</h3>
                <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit,
                    Aktualität,
                    Zuverlässigkeit und Vollständigkeit der Informationen.</p>

                <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem
                    Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der
                    Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>

                <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder
                    das
                    gesamte Angebot ohne besondere Ankündigung zu verändern, zu ergänzen, zu löschen oder die
                    Veröffentlichung zeitweise oder endgültig einzustellen.</p>

                <br />
                <h3>Haftungsausschluss für Links</h3>
                <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs. Es wird
                    jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
                    erfolgen auf eigene Gefahr des jeweiligen Nutzers.</p>

                <br />
                <h3>Urheberrechte</h3>
                <p>Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen Dateien auf dieser
                    Website, gehören ausschliesslich der SESERA AG oder den speziell genannten
                    Rechteinhabern. Für die Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des
                    Urheberrechtsträgers im Voraus einzuholen.</p>

            </div>
            <div class="col-md-2">
            </div>
        </div>
    </div>


    <div class="blockSpaceTop">
        <div></div>
    </div>
</div>

<app-fe-contact-footer></app-fe-contact-footer>