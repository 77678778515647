import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RealEstateService } from '../services/real-estate.service';
import { DomSanitizer } from '@angular/platform-browser';
import { RealEstate } from '../models/real-estate';
import { fadeInAnimation } from '../animations/fade-in-animation';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { routes } from '../app-routing.module';

@Component({
  selector: 'app-fe-real-estate',
  templateUrl: './fe-real-estate.component.html',
  styleUrls: ['./fe-real-estate.component.scss']
})
export class FeRealEstateComponent implements OnInit {

  isCurrentUser: boolean;

  data: RealEstate;
  estateImagesUrls: string[];

  mapsUrl: any;
  errorMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: RealEstateService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      try {
        const parseObjData = await this.service.getById(params.id);
        if (parseObjData == null) {
          throw Error('Immobilie konnte nicht gefunden werden.');
        }

        if (parseObjData.get('rooms') != null) {
          parseObjData.set('rooms', parseObjData.get('rooms').replace('.5', ' ½'));
        }

        if (parseObjData.get('livingSpace') != null) {
          // parseObjData.set('livingSpace', parseObjData.get('livingSpace').replace('cm2', ''));
        }

        this.data = this.service.parseObj2Interface(parseObjData) as RealEstate;

        this.estateImagesUrls = await this.service.getImageUrlFromEstate(params.id);

        this.isCurrentUser = this.service.isCurrentUser();
        this.setMapLocation(this.data.address);
      } catch (ex) {
        console.error(ex);
        this.errorMessage =
          'Daten konten nicht geladen werden. Versuchen Sie es später erneut.';
      }
    });
  }

  setMapLocation(placeName: string) {
    if (!placeName) {
      return;
    }
    const iframeUrl = 'https://www.google.com/maps/embed/v1/place?q=PLACENAME&key=AIzaSyCFfl_3OH-qcoaG2FCKXap6uq_iYTi3b44';
    const encodedPlaceName = encodeURI(
      placeName
        .split(' ')
        .join('')
        .toLocaleLowerCase()
        .replace(/(?:\r\n|\r|\n)/g, ' ')
    );

    const mapUrl = iframeUrl.replace('PLACENAME', encodedPlaceName);
    this.mapsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(mapUrl); // Angular Security
  }

  edit() {
    this.router.navigateByUrl('/admin/immobilie/' + this.data.objectId);
  }
}
