import { Component, OnInit } from '@angular/core';
import { ParameterService } from '../services/parameter.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
  styleUrls: ['./parameter.component.scss']
})
export class ParameterComponent implements OnInit {

  data: any[];
  constructor() { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    try {

      this.data = await ParameterService.getAllParameters();

    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  editParam(param) {
    Swal.fire({
      text: 'Parameter "' + param.get('key') + '" bearbeiten',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Speichern',
      showLoaderOnConfirm: true,
      inputValue: param.get('value'),
      preConfirm: (login) => {

        param.set('value', login);

        return param.save()
          .then(response => {
           
            return response.get('value');
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Es ist ein Fehler aufgetreten.`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: `Gesichert`,
          
        })
      }
    })
  }

}
