import { Component, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { ActivatedRoute, Router } from '@angular/router';
import { RealEstate } from '../models/real-estate';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-real-estate',
  templateUrl: './real-estate.component.html',
  styleUrls: ['./real-estate.component.scss']
})
export class RealEstateComponent implements OnInit {

  Editor = ClassicEditor;
  showModal: boolean;
  isNewOne: boolean;
  dataHasBeenSaved: boolean;
  data: any;
  estateImages: any[];
  formModel: RealEstate;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.formModel = {} as RealEstate;
    this.showModal = false;
    this.dataHasBeenSaved = false;

    this.route.params.subscribe(async params => {
      if (params.id === 'new') {
        const GameScore = Parse.Object.extend('RealEstate');
        this.data = new GameScore();
        this.isNewOne = true;
      } else {
        this.loadData(params.id);
      }

    });
  }

  async loadImagesOfRealEstate() {
    const relation = this.data.relation('images');

    // generate a query based on that relation
    const query = relation.query();
    this.estateImages = await query.find();
  }

  async setCoverImage(parseImageObject) {
    this.data.set('coverImage', parseImageObject);
    await this.save(false);
  }

  async newImages2Save(item) {

    try {
      this.showModal = false;
      if (item == null) {
        return;
      }

      const relation = this.data.relation('images');

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < item.length; i++) {
        const element = item[i];
        relation.add(element);
      }

      await this.save(false);
      if (!this.estateImages || this.estateImages.length === 0) {
        // If there are no Images, set the first one as cover image.
        await this.loadImagesOfRealEstate();
        this.setCoverImage(this.estateImages[0]);
      } else {
        await this.loadImagesOfRealEstate();
      }
    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  async removeImage(item) {

    try {

      const relation = this.data.relation('images');

      // tslint:disable-next-line:prefer-for-of
      relation.remove(item);


      await this.save(false);
      await this.loadImagesOfRealEstate();
    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  setStatus(status: boolean) {
    this.data.set('status', status);
    this.formModel.status = status;
    this.save();
  }

  async loadData(id = '') {
    try {

      if (id === '') {
        id = this.data.id;
      }

      const GameScore = Parse.Object.extend('RealEstate');
      const query = new Parse.Query(GameScore);
      query.equalTo('objectId', id);
      this.data = await query.first();

      this.formModel = this.parseObj2Interface(this.data) as RealEstate;
      await this.loadImagesOfRealEstate();
    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  async save(saveTextValues = true) {
    try {
      if (saveTextValues) { this.interface2ParseObj(this.formModel, this.data); }
      this.data = await this.data.save();
      this.dataHasBeenSaved = true;

      if (saveTextValues && this.isNewOne) {
        window.open('/admin/immobilie/' + this.data.id, '_self');
      }
    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  async delete() {
    try {
      if (!confirm('Wollen Sie diese Immobilie wirklich löschen?')) {
        return;
      }

      await this.data.destroy();
      this.router.navigateByUrl('/admin');
    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  private interface2ParseObj(interf, parseObj) {
    // tslint:disable-next-line:forin
    for (const key in interf) {
      if (key !== 'createdAt' && key !== 'updatedAt' && key !== 'objectId' && key !== 'images' && key !== 'coverImage') {
        parseObj.set(key, interf[key]);
      }
    }
    return parseObj;
  }

  private parseObj2Interface(parseObj) {
    const returnVal = {};
    // tslint:disable-next-line:forin
    for (const key in parseObj.attributes) {
      returnVal[key] = parseObj.attributes[key];
    }
    return returnVal;
  }

}
