<nav class="navbar navbar-expand-lg navbar-light bg-light" >
    <a class="navbar-brand" >Media Center</a>
    <div class=" navbar-collapse" id="navbarSupportedContent">
        <ul *ngIf="windowType" class="navbar-nav mr-auto">

        </ul>
        <form class="form-inline my-2 my-lg-0">
            <button class="btn btn-outline-secondary my-2 my-sm-0"  [disabled]="uploadInProgress || files2Upload.length != 0" type="button" ngxFilePicker accept="image/*" multiple
                (filePick)="loadFiles($event)">{{files2Upload && files2Upload.length === 0 ? 'Bilder hochladen' : files2Upload.length + ' Bilder selektiert'}}</button>
                &nbsp;&nbsp;
            <button *ngIf="files2Upload && files2Upload.length != 0" [disabled]="uploadInProgress" class="btn btn-success my-2 my-sm-0"
                type="button" (click)="uploadLoadedFiles()">Upload</button>
        </form>
    </div>
</nav>

<nav class="navbar navbar-expand-lg navbar-light bg-light navOnBotton" *ngIf="windowType">
    <a class="navbar-brand" >{{chosenItems.length}} Bilder ausgewählt</a>
    <div class=" navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

        </ul>
        <form class="form-inline my-2 my-lg-0">
            <button class="btn btn-outline-secondary my-2 my-sm-0"
                type="button" (click)="exitWindow()">Abbrechen</button>
            &nbsp;&nbsp;
            <button [disabled]="chosenItems.length == 0" class="btn btn-success my-2 my-sm-0"
                type="button" (click)="setChosenItems()">Fertig</button>
        </form>
    </div>
</nav>

<div class="navSpacer"><div></div></div>



<div class="content">
    <app-loader3 *ngIf="!data"></app-loader3>
    <p *ngIf="data && data.length == 0" class="noitemsFound">Zurzeit gibt es keine Medien.</p>

    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col-md-4" *ngFor="let item of data">
                <div>

                    <div class="card" style="width: 18rem;" [ngStyle]="{border: item.get('chosen') ? '2px solid #007bff' : '1px solid rgba(0,0,0,.125)', 
                    padding: item.get('chosen') ? '1px 0 1px 0' : '0px'}">
                        <img class="card-img-top" [src]="item.get('file').url()" alt="Card image cap">
                        <div class="card-body" *ngIf="!windowType || windowType != 'multiplechooser'">
                            <p class="card-text">{{item.attributes.name}}<br />{{item.attributes.createdAt | date: 'dd.MM.yyyy HH:mm'}}</p>
                            <a class="btn btn-danger" (click)="deleteFile(item)">Löschen</a>
                        </div>
                        <div class="card-body" *ngIf="windowType && windowType == 'multiplechooser'">
                            <a class="btn btn-outline-primary" *ngIf="!item.get('chosen')" (click)="addItem2ChosenOnes(item)">Wählen</a>
                            <a class="btn btn-outline-danger" *ngIf="item.get('chosen')" (click)="removeItemFromChosenOnes(item)">Abwählen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navSpacer"><div></div></div>