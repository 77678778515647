import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import * as Parse from 'parse';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  data: any[];
  constructor() { }

  ngOnInit(): void {
    this.loadData();
  }

  async loadData() {
    try {

      const GameScore = Parse.Object.extend('_User');
      const query = new Parse.Query(GameScore);
      this.data = await query.find();

    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }

  addUser() {
    Swal.fire({
      text: 'Neuen Benutzer erstellen',
      input: 'text',
      inputPlaceholder: 'E-Mail des Benutzers',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Erstellen',
      showLoaderOnConfirm: true,
      preConfirm: (input) => {

        var GameScore = Parse.Object.extend("_User");
        var gameScore = new GameScore();
        gameScore.set('username', input);
        gameScore.set('password', 'justSomePassword');
        return gameScore.save()
          .then(response => {

            return response.get('email');
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Es ist ein Fehler aufgetreten.`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: `Erfolg`,
          text: 'Der Benutzer ' + result.value + ' wurde erfolgreich erstellt.'
        });
        this.loadData();
      }
    })
  }

  async deleteUser(user) {
    try {
      if (confirm("Wollen Sie diesen Benutzer wirklich löschen?")) {
        await Parse.Cloud.run('deleteUser', { user2delete: user.id });

        Swal.fire({
          icon: 'success',
          title: `Erfolg`,
          text: 'Der Benutzer ' + user.get('username') + ' wurde gelöscht.'
        });

        await this.loadData();
      }
    } catch (ex) {
      alert('Es ist ein Fehler aufgetreten');
      console.error(ex);
    }
  }


  changePassword(user) {
    Swal.fire({
      text: 'Passwort ändern',
      input: 'text',
      inputPlaceholder: 'Neues Passwort für ' + user.get('username'),
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Ändern',
      showLoaderOnConfirm: true,
      preConfirm: (input) => {

        return Parse.Cloud.run('changePassword', { userId: user.id, newPassword: input })
          .then(response => {

            return 'success';
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Es ist ein Fehler aufgetreten.`
            )
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: `Erfolg`,
          text: 'Das Passwort für den Benutzer ' + user.get('username') + ' wurde erfolgreich geändert.'
        });
        this.loadData();
      }
    })
  }
}
