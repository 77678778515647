<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">Parameter</a>
</nav>
<div class="navSpacer">
    <div></div>
</div>
<div class="content">
    <app-loader3 *ngIf="!data"></app-loader3>
    <p *ngIf="data && data.length == 0" class="noitemsFound">Zurzeit gibt es keine Parameter.</p>

    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col-12" *ngFor="let item of data">
                <div>

                    <div class="card">
                        <div class="card-body">
                            <p class="card-text">Name: {{item.attributes.key}}<br />
                                Wert: <strong>{{item.attributes.value}}</strong><br /><span class="description">{{item.attributes.description}}</span> </p>

                            <a class="btn btn-outline-primary" (click)="editParam(item)">Bearbeiten</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>