import { Component } from '@angular/core';
import * as Parse from 'parse';
import { Router, RouterOutlet } from '@angular/router';
import { fadeInAnimation } from './animations/fade-in-animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInAnimation]
})
export class AppComponent {
  title = 'SESERA';
  showNavBar: boolean;

  constructor(private router: Router) {
    this.showNavBar = true;

    Parse.initialize('HTgRFMwSfVpfoJooLzPtcPNzsP9AqbXs6wEOGu8A4LHtTL3SrieZp0xgINhHmthTHGc2K');
    (Parse as any).serverURL = 'https://api.sesera.ch/parse';
    //(Parse as any).serverURL = 'http://localhost:1337/parse';

    router.events.subscribe((val) => {
      window.scrollTo(0, 0);
      if (this.router.url.indexOf('admin') !== -1 || this.router.url.indexOf('bilder') !== -1) {
        this.showNavBar = false;
      } else {
        this.showNavBar = true;
      }
    });
    
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
