<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="material-icons" routerLink="/admin">
        arrow_back
    </span>&nbsp;&nbsp;

    <a class="navbar-brand">Immobilie <span *ngIf="data">"{{formModel.title}}" <span style="color: gray;">- Status
                {{data.attributes.status ? 'veröffentlicht' : 'verborgen'}}</span></span></a>
    <div class=" navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

        </ul>
        <form class="form-inline my-2 my-lg-0">
            <button *ngIf="data && !data.attributes.status" class="btn btn-outline-secondary my-2 my-sm-0" type="button"
                (click)="setStatus(true)">Veröffentlichen</button>

            <button *ngIf="data && data.attributes.status" class="btn btn-outline-secondary my-2 my-sm-0" type="button"
                (click)="setStatus(false)">Verbergen</button>
            &nbsp;&nbsp;
            <button *ngIf="data" class="btn btn-outline-success my-2 my-sm-0" type="button"
                (click)="save()">Speichern</button>
        </form>
    </div>
</nav>
<div class="content">
    <app-loader3 *ngIf="!data"></app-loader3>

    <div class="container" *ngIf="data">
        <div class="row">
            <div class="col-12">
                <div>

                    <div class="alert alert-success" role="alert" *ngIf="dataHasBeenSaved">
                        <span (click)="dataHasBeenSaved = false">
                        Daten wurden erfolgreich gespeichert.</span> &nbsp;<a *ngIf="data.id" [routerLink]="'/immobilie/' + data.id">anzeigen</a>
                    </div>

                    <div class="form-group">
                        <label>Titel</label>
                        <input type="text" [(ngModel)]="formModel.title" class="form-control" placeholder="Titel">
                    </div>

                    <div class="form-group">
                        <label>Subtitel</label>
                        <input type="text" [(ngModel)]="formModel.subtitle" class="form-control" placeholder="Subtitel">
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Adresse</label>
                            <textarea type="text" [(ngModel)]="formModel.address" class="form-control"
                                placeholder="Adresse"
                                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'></textarea>
                        </div>
                    </div>

                    <div class="col-md-8">

                        <div class="form-group">
                            <label>Beschreibung</label>
                            <ckeditor [editor]="Editor" [(ngModel)]="formModel.description"></ckeditor>

                            <!--textarea type="text" [(ngModel)]="formModel.description" class="form-control"
                                placeholder="Beschreibung"
                                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'></textarea-->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Bruttomiete inkl. NK </label>
                    <input type="text" [(ngModel)]="formModel.bruttomiete" class="form-control"
                        placeholder="Bruttomiete inkl. NK">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Wohnfläche</label>
                    <input type="text" [(ngModel)]="formModel.livingSpace" class="form-control"
                        placeholder="Wohnfläche">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Zimmer</label>
                    <input type="text" [(ngModel)]="formModel.rooms" class="form-control"
                        placeholder="Zimmer">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Verfügbarkeit</label>
                    <input type="text" [(ngModel)]="formModel.availability" class="form-control"
                        placeholder="Verfügbarkeit">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label>Immoscout24 Link</label>
                    <input type="text" [(ngModel)]="formModel.externalAdUrl" class="form-control"
                        placeholder="Immoscout24 Link">
                </div>
            </div>
            <div class="col-12">
                <label>Bilder</label>
            </div>
        </div>

        <div class="row imageRow" *ngIf="estateImages && estateImages.length != 0">
            <div class="col-sm-3" *ngFor="let item of estateImages">
                <div>
                    <img [src]="item.get('file').url()" width="100%" />

                    <div class="row" *ngIf="!this.data.get('coverImage') || this.data.get('coverImage').id !== item.id">
                        <div class="col-6 removePaddingRight" (click)="setCoverImage(item)">
                            <div class="imageDivCover">
                                <p>Cover</p>
                            </div>
                        </div>
                        <div class="col-6 removePaddingLeft" (click)="removeImage(item)">
                            <div class="imageDiv">
                                <p>Entfernen</p>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="this.data.get('coverImage') && this.data.get('coverImage').id === item.id">
                        <div class="col-12">
                            <div class="imageDivCoverSelected">
                                <p>Cover Bild</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button type="button" class="btn btn-outline-primary btnAddImages" (click)="showModal = true">Bilder
            hinzufügen</button>

        <div class="row">
            <div class="col-12"><br /><br />
                <button type="button" class="btn btn-outline-danger btnAddImages" *ngIf="this.data"
                    (click)="delete()">Immobilie löschen</button>
            </div>
        </div>



    </div>
</div>


<!-- The Modal -->
<div id="myModal" class="modal" [ngStyle]="{'display': showModal ? 'block' : 'none'}">
    <div class="modal-content">
        <app-media windowType="multiplechooser" (onItemsChoosed)="newImages2Save($event[0])"></app-media>
    </div>
</div>